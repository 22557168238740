
<template>
    <div class="mx-auto" style="max-width: 1000px; position: relative">
      <Card class="card-custom card-block">
        <template #title>
          <div class="column-wrapper align-center">
            <div class="column-three text-left"><back-button /></div>
              <h4 class="c-black flex-1 column-three">{{$t('segment_data')}}</h4>
              <div class="column-three"></div>
          </div>
        </template>
        <template #content>
          <ValidationObserver ref="observer" v-slot="{ invalid, validate }">
            <Skeleton width="100%" height="500px" v-if="loadingPage"></Skeleton>
                  <Card class="card-custom form-block" v-else>
                    <template #title>
                      
                    </template>
                    <template #content>
                        <div class="space-y-1">
                    <ValidationProvider
                :name="$t('name')"
                rules="required"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">{{ $t('name') }}<span class="c-red">*</span>:</h4>
                <div class="wrapper-input">
                    <InputText
                class="fw"
                :class="{ 'p-invalid' : errors.length > 0 }"
                :placeholder="$t('name')" 
                v-model="segment.name" 
                />
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
                    <ValidationProvider
                :name="$t('description')"
                rules="required"
                v-slot="{ errors, validate, validated }"
              > 
                <h4 class="c-text white-space title-input">{{ $t('description') }}<span class="c-red">*</span>:</h4>
                <div class="wrapper-input">
                    <InputText
                class="fw"
                :class="{ 'p-invalid' : errors.length > 0 }"
                :placeholder="$t('description')" 
                v-model="segment.description" 
                />
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
          <!-- <span> 
            <h4 class="c-text white-space title-input">
              {{ $t('active') }}: 
            </h4>
            <div class="d-flex flex-column">
              <InputSwitch class="p-inputswitch-success" v-model="segment.is_enabled" />
            </div>
          </span> -->
        </div>
        <Divider />
        <div class="segment-conditions space-y-1">
          <div class="column-wrapper align-center">
            <div class="column-three">
            </div>
            <div class="column-three">
              <h2>{{ $t('conditions') }}</h2>
            </div>
            
              <div class="column-three text-right">
                <Button :label="$t('add_rule')" 
                icon="pi pi-plus"
                class="p-button-sm p-button-success"
                @click="addRule" />
                <h5 v-if="rulesError" class="c-red">{{ $t('required_field') }}</h5>
              </div>
          </div>
          <Card class="card-custom" v-for="(rule, ruleIndex) in segment.selection_rules" :key="ruleIndex">
            <template #content>
              <div class="space-y-1">
              <div class="d-flex align-center justify-space-between">
                <SelectButton :options="logicOperators"
                                optionLabel="label"
                                optionValue="value"
                                v-model="rule.match_type" 
                                class="text-left"
                                />
                                <Button :label="$t('remove_rule')" 
                                v-if="segment.selection_rules.length > 1"
                icon="pi pi-minus"
                class="p-button-sm p-button-danger"
                @click="removeRule(ruleIndex)" />
              </div>
              <Card v-for="(condition, conditionIndex) in rule.conditions" :key="conditionIndex" class="relative">
                <template #content>
                  <Tag :value="rule.match_type"
                  class="type-tag"
                    v-if="conditionIndex !== 0"
                    ></Tag>
                    <div class="d-flex align-start gap-1">
                      <ValidationProvider
                      class="w-full"
                :name="$t('field')"
                rules="required"
                v-slot="{ errors, validate, validated }"
              > 
                <div class="wrapper-input">
                  <Dropdown :options="fields" 
                  :filter="true"
                   dataKey="field"
                  class="custom-dropdown w-full"
                  v-model="condition.field" 
                  :placeholder="$t('field')">
                  <template #value="slotProps">
                    <div v-if="slotProps.value.field">
                        <div>{{ $t(slotProps.value.field)}}</div>
                    </div>
                    <span v-else>
                        {{slotProps.placeholder}}
                    </span>
                </template>
                <template #option="slotProps">
                        <div>{{ $t(slotProps.option.field)}}</div>
                </template>
                  </Dropdown>
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
                      <ValidationProvider
                      class="w-full"
                :name="$t('condition')"
                rules="required"
                v-slot="{ errors, validate, validated }"
              > 
                <div class="wrapper-input">
                  <Dropdown 
                      class="w-full custom-dropdown"
                      :disabled="!condition.field.field"
                      :options="condition.field.operators" 
                      v-model="condition.operator" :placeholder="$t('condition')" >
                      <template #value="slotProps">
                        <div v-if="slotProps.value">
                            <div>{{ $t(slotProps.value)}}</div>
                        </div>
                        <span v-else>
                            {{slotProps.placeholder}}
                        </span>
                    </template>
                    <template #option="slotProps">
                            <div>{{ $t(slotProps.option)}}</div>
                    </template>
                      </Dropdown>
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
                      <ValidationProvider
                      class="w-full"
                :name="$t('value')"
                rules="required"
                v-slot="{ errors, validate, validated }"
              > 
                <div class="wrapper-input">
                  <InputText 
                  v-if="
                  condition.field.field !== 'currency' &&
                  condition.field.field !== 'country_code' &&
                  condition.field.field_type && condition.field.field_type === 'string' || !condition.field.field_type"
                  :disabled="!condition.operator"
                  class="w-full p-inputtext-sm" v-model="condition.value" :placeholder="$t('value')" />
                  <Dropdown 
                  v-else-if="
                  condition.field.field === 'currency' &&
                  condition.field.field_type && condition.field.field_type === 'string'"
                  class="w-full custom-dropdown"
                  v-model="condition.value" 
                  :disabled="!condition.operator"
                  :options="currencies"
                  :placeholder="$t('value')" :filter="true" :emptyFilterMessage="$t('no_data')">
                </Dropdown>
                  <Dropdown 
                  v-else-if="
                  condition.field.field === 'country_code' &&
                  condition.field.field_type && condition.field.field_type === 'string'"
                  class="w-full custom-dropdown"
                  v-model="condition.value" 
                  optionValue="country_code"
                  optionLabel="country_name"
                  :disabled="!condition.operator"
                  :options="countries.items"
                  :placeholder="$t('value')" :filter="true" :emptyFilterMessage="$t('no_data')">
                  <template #option="slotProps">
                    <div>
                        <div>{{ `${slotProps.option.country_name} - ${slotProps.option.country_code}` }}</div>
                    </div>
                </template>
                <template #value="slotProps">
                  <div v-if="slotProps.value">
                      <div>{{ `${slotProps.value}` }}</div>
                  </div>
                  <span v-else>
                      {{slotProps.placeholder}}
                  </span>
              </template>
                </Dropdown>
                  <Calendar 
                  v-else-if="condition.field.field_type && condition.field.field_type === 'date'"
                  v-model="condition.value"
                  :disabled="!condition.operator"
                  :showIcon="true" 
                  :placeholder="$t('value')"
                  class="w-full"
                  />
                  <InputNumber
                  :min="0"
                  :max="9999999"
                  showButtons
                  v-else-if="condition.field.field_type && condition.field.field_type === 'number'"
                  :disabled="!condition.operator"
                  class="w-full" v-model="condition.value" :placeholder="$t('value')" />
                  <span v-if="errors.length" class="p-error mt-1 font-weight-bold">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
                      
                      
                      <div>
                        <Button icon="pi pi-trash" 
                        class="p-button-sm p-button-danger"
                        @click="removeCondition(ruleIndex, conditionIndex)" />
                      </div>
                    </div>
                    
                </template>
              </Card>
              <span class="justify-center">
                <Button :label="$t('add_condition')" 
                icon="pi pi-plus" 
                class="p-button-sm p-button-success"
                
                @click="addCondition(ruleIndex)" />
              </span>
            </div>
            </template>
          </Card>
          
        </div>
      </template>
      
      </Card>
              <Button @click="handleSubmit(validate)" 
          :disabled="formLoading" 
          :loading="formLoading"
          :label="$t('create')"
                  icon="pi pi-save" class="p-button-sm p-button-success fw mt-2" />
          </ValidationObserver>
              
        </template>
      </Card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
name: 'CreateSegment',
computed: {
  ...mapGetters({
      currencies: 'getFilteredCurrencies',
      conditions: 'segments/getConditionsFields',
      countries: 'geo/getGeoSettings',
  }),
  fields() {
      if (!this.conditions) return [];
      return this.conditions.map(cond => ({ field: cond.field, operators: cond.operators, field_type: cond.field_type }));
    }
},
watch: {
},
data() {
  return {
  loadingPage: false,
  formLoading: false,
  rulesError: false,
  filters: {
    page: 1,
    size: 248,
  },
  segment: {
    name: '',
    description: '',
    selection_rules: [
      {
            match_type: 'And', 
            conditions: [
              { field: {
                field: null,
              }, operator: '', value: '' }
            ]
          }
    ]
  },
  logicOperators: [
    { label: this.$t('and'), value: 'And' },
    { label: this.$t('or'), value: 'Or' },
  ]
  }
},
async mounted() {
  this.loadingPage = true;
  if (this.countries.items.length < 200) {
    await this.getCountries();
  }
  this.loadingPage = false;
},
methods: {
  async getCountries() {
    try {
          await this.$store.dispatch('geo/awaitGetGeoSettings', this.filters);
        } catch (error) {
          this.$toast.open({
            summary: this.$t("just_error"),
            life: 4000,
            severity: 'error',
          })
        } 
  },
    addCondition(ruleIndex) {
      const newCondition = { field: {
                field: null,
              }, operator: '', value: '' };
      this.segment.selection_rules[ruleIndex].conditions.push(newCondition);
    },
    addRule() {
      const newRule = {
        match_type: 'And', 
        conditions: [
          { field: {
                field: null,
              }, operator: '', value: '' }
        ]
      };
      this.segment.selection_rules.push(newRule);
    },
    removeRule(ruleIndex) {
      this.segment.selection_rules.splice(ruleIndex, 1);
    },
    removeCondition(ruleIndex, conditionIndex) {
      this.segment.selection_rules[ruleIndex].conditions.splice(conditionIndex, 1);
      if (this.segment.selection_rules[ruleIndex].conditions.length === 0) {
      this.segment.selection_rules.splice(ruleIndex, 1);
      }
    },
  handleSubmit(validate) {
      validate().then(valid => {
          if (valid) {
            this.rulesError = false;
            if (!this.segment.selection_rules.length) {
              this.rulesError = true;
              this.addRule();
              return
            }
              this.createSegment();
          }
      })
  },
  getFormattedDate(value) {
    const date = new Date(value);
    return date.toISOString().split('T')[0];
  },
  toTransormData(){
    this.segment = {
    ...this.segment,
    selection_rules: this.segment.selection_rules.map(rule => ({
      match_type: rule.match_type.toLowerCase(), 
      conditions: rule.conditions.map(condition => {
          // Определяем значение поля
          let fieldValue = condition.value;
          if (condition.field.field_type === "date") {
            fieldValue = this.getFormattedDate(fieldValue);
          }

          return {
            field: condition.field.field, // Применяем форматированное значение
            operator: condition.operator,
            value: fieldValue
          };
        })
    }))
  };
  },
async createSegment() {
    this.formLoading = true;
    this.toTransormData();
  try {
      await this.$store.dispatch('segments/createSegment', this.segment);
      await this.$router.push('/segments');
      this.segment = {
        name: '',
        description: '',
        selection_rules: [
      {
            match_type: 'And', 
            conditions: [
              { field: {
                field: null,
              }, operator: '', value: '' }
            ]
          }
    ]
        };
      this.$toast.add({ severity: 'success', summary: this.$t('segment_created'), life: 4000 });
        await this.dispatchSegments();
    } catch (error) {
      this.$toast.add({ severity: 'error', summary: this.$t('just_error'),
      detail: error,
      life: 4000 });
      this.segment = {
        name: '',
        description: '',
        selection_rules: [
      {
            match_type: 'And', 
            conditions: [
              { field: {
                field: null,
              }, operator: '', value: '' }
            ]
          }
    ]
        };
    } finally{
      this.formLoading = false;
    }
},
  async dispatchSegments() {
      await this.$store.dispatch('segments/awaitGetSegments');
  },
},
}
</script>

<style lang="scss" scoped>
.type-tag{
  position: absolute;
  z-index: 1;
  top: -50%;
  left: -5px;
  transform: translate(0px,80%);
}
</style>